/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import VisuallyHidden from './visually-hidden';

type Props = {
  children: React.ReactNode;
};

const SkipNavLink = ({ children, ...props }: Props) => (
  <VisuallyHidden
    tag="a"
    sx={{
      '&:focus': {
        padding: 3,
        position: `fixed`,
        top: `15px`,
        left: `15px`,
        backgroundColor: `heading`,
        color: `background`,
        zIndex: 1,
        width: `auto`,
        height: `auto`,
        clip: `auto`,
        textDecoration: `none`
      }
    }}
    href="#skip-nav"
    data-skip-link="true"
  >
    {children}
  </VisuallyHidden>
);

export default SkipNavLink;
