/** @jsx jsx */
import React from 'react';
import { Global } from '@emotion/core';
import { Box, Container, jsx, SxStyleProp } from 'theme-ui';
import 'typeface-ibm-plex-sans';
import SEO from './seo';
import Header from './header';
import Footer from './footer';
import codeStyles from '../styles/code';
import SkipNavLink from './skip-nav';
import { ClassNameProp } from '../utils/types';

type Props = ClassNameProp & {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, className }) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        '*': {
          boxSizing: 'inherit'
        },
        html: {
          WebkitTextSizeAdjust: '100%',
          fontSize: '16px'
        },
        '::selection': {
          backgroundColor: theme.colors.text,
          color: theme.colors.background
        },
        a: { color: `text` }
      })}
    />
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
    <Container
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        minHeight: '100vh'
      }}
    >
      <Header />
      <Box id="skip-nav" sx={codeStyles as SxStyleProp} className={className}>
        {children}
      </Box>
      <Footer />
    </Container>
  </React.Fragment>
);

export default Layout;
