import { useEffect, useState } from 'react';

const useReducedMotion = () => {
  const [reducedMotion, setReducedMotion] = useState(false);

  useEffect(() => {
    var queryList = window.matchMedia('(prefers-reduced-motion: reduce)');

    setReducedMotion(queryList.matches);

    const handleChange = (event: MediaQueryListEvent) => setReducedMotion(event.matches);

    queryList.addListener(handleChange);

    return () => queryList.removeListener(handleChange);
  }, [setReducedMotion]);

  return reducedMotion;
};

export default useReducedMotion;
